import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import TableColor from "../components/global/TableColor";


function Painting() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Painting">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Painting"
          Subheader="Painting"
          bgimg={`${rpdata?.gallery?.[8]}`}
        />

        <div className="justify-center flex flex-wrap-reverse items-center p-10 gap-6 border-solid-2 m-7 ">
            <div className="text-center flex-wrap md:w-[40%] ">
                <h3>{rpdata?.works?.[7].name}</h3>
                <p>
                    {rpdata?.works?.[7].description}
                </p>
            </div>
            <div className="md:w-[40%] logocomp p-10">
                <img src={rpdata?.works?.[7].gallery} alt="" />
            </div>
        </div>
        
        <div className="justify-center items-center flex mb-20">
        <div className="">
            <TableColor />
        </div>
        </div>
       
      </div>
    </BaseLayout>
  );
}

export default Painting;
