import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'; // Importa los iconos de react-icons

const ToggleSection = ({ title, children, isOpen, toggleSection }) => {
    return (
        <div className="p-4 my-2 bg-white rounded-lg shadow">
            <button
                onClick={toggleSection}
                className="w-full flex justify-between items-center px-4 py-2 text-gray-800 font-semibold rounded-lg bg-gray-100 hover:bg-gray-200 transition duration-300"
            >
                <span>{title}</span>
                {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />} {/* Muestra una flecha hacia arriba o hacia abajo según si la sección está abierta o cerrada */}
            </button>
            {isOpen && (
                <div className="p-4 mt-2 bg-gray-50 rounded-b-lg border-t border-gray-200 text-black">
                    {children}
                </div>
            )}
        </div>
    );
};

export default ToggleSection;
