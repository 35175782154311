import React, { useCallback, useContext, useEffect, useState } from "react";
import ToggleSection from "./ToggleSection";
import { GlobalDataContext } from "../../context/context";
import { PrintData } from "./PrintComponent";
import InvoiceController from "./controllers";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { MdSend } from "react-icons/md";
import SignatureCanvas from "react-signature-canvas";
import { useRef } from "react";
import { IoIosSync } from "react-icons/io";

const FormInvoice = ({ id, isUpdate, setShowInvoice }) => {
  const [isLoading, SetisLoading] = useState(false);
  function getFormattedDate() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // getMonth() devuelve un índice basado en cero, así que se suma 1
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // el '0' se convierte en '12'
    minutes = minutes < 10 ? "0" + minutes : minutes; // agrega cero si los minutos son menos de 10

    return `${year}-${month}-${day}-${hours}:${minutes}${ampm}`;
  }

  const { rpdata } = useContext(GlobalDataContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    invoiceName: "" || `Invoice ${getFormattedDate()}`,
    companyLicense: "" | rpdata?.companyLicense || "",
    invoiceNumber: "",
    companyAddress: "" || rpdata?.dbPrincipal?.location[0]?.address,
    companyPhone: "" || rpdata?.dbPrincipal?.phones[0].phone,
    companyEmail: "" || rpdata?.dbPrincipal?.emails[0].email,
    clientName: "",
    clientAddress: "",
    clientPhone: "",
    items: [{ quantity: 0, description: "", unitPrice: 0, completed: false }],
    subtotal: 0,
    descuento: 0,
    total: 0,
    customerSignature: "",
    ownerSignature: "",
    terms:
      "" ||
      "WHEN YOU ENGAGE IN THER BUISNESS.YOUR ARE ACCEPTING THE FOLLOWING TERMS AND CONDITIONS. DEPOSIT OF 25% OF TOTAL PRICE REQUIRED AT TIME ORDER IS PLACED REMAINING BALANCE MUST BE PAID IN FULL AT TIME THAT JOB IS COMPLET.",
    clienteId: rpdata.docId || "",
    currentDate: new Date().toISOString().slice(0, 10),
  });

  useEffect(() => {
    if (isUpdate && id) {
      InvoiceController.getInvoiceById(id)
        .then((data) => {
          setFormData((currentFormData) => ({ ...currentFormData, ...data }));
        })
        .catch((err) => {
          const errorMessage = `Failed to fetch invoices: ` + err.message;
          toast.error(errorMessage);
        });
    }
  }, [id, isUpdate]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addItem = () => {
    setFormData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        { quantity: 0, description: "", unitPrice: 0, completed: false },
      ],
    }));
  };

  // Función para calcular el subtotal, descuento y total
  const calculateTotal = useCallback(
    (items) => {
      let subtotal = items.reduce((acc, item) => {
        return item.completed ? acc + item.quantity * item.unitPrice : acc;
      }, 0);

      const descuento = parseFloat(formData.descuento) || 0;
      const totalWithDiscount = subtotal - (subtotal > 0 ? descuento : 0);

      setFormData((prevState) => ({
        ...prevState,
        subtotal: subtotal,
        total: totalWithDiscount,
      }));
    },
    [formData.descuento]
  ); // Incluye aquí cualquier otra dependencia externa usada en `calculateTotal`

  useEffect(() => {
    calculateTotal(formData.items);
  }, [formData.descuento, formData.items, calculateTotal]);

  const inputStyle =
    "mt-1 block w-full px-3 text-black py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm";

  // Función para manejar cambios en los elementos de la factura
  const handleItemChange = (index, e) => {
    const { name, type, checked } = e.target;
    const value = type === "checkbox" ? checked : e.target.value;

    // Convert value to number for quantity and unitPrice fields if they are not checkboxes
    const formattedValue =
      name === "quantity" || name === "unitPrice"
        ? parseFloat(value) || 0
        : value;

    // Update the items with the new value for the checkbox or other inputs
    const updatedItems = formData.items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [name]: type === "checkbox" ? checked : formattedValue,
        };
      }
      return item;
    });

    // Update the form data with the new items array
    setFormData((prevState) => ({
      ...prevState,
      items: updatedItems,
    }));

    // Recalculate the total only if the service is marked as completed
    if (updatedItems[index].completed) {
      calculateTotal();
    }
  };

  const handleToggleCompleted = (index) => {
    const newCompletedStatus = !formData.items[index].completed;

    // Actualiza el estado de 'completed' para el ítem específico
    const updatedItems = formData.items.map((item, i) => {
      if (i === index) {
        return { ...item, completed: newCompletedStatus };
      }
      return item;
    });

    // Actualiza los items en el estado del formulario
    setFormData((prevState) => ({
      ...prevState,
      items: updatedItems,
    }));

    // Recalcula el total siempre que se cambia el estado de 'completed'
    calculateTotal(updatedItems);
  };

  const handleSubmit = (e) => {
    SetisLoading(true);  // Activar el indicador de carga
    e.preventDefault();

    const action = isUpdate
      ? InvoiceController.updateInvoice(id, formData)
      : InvoiceController.createInvoice(formData);

    action
      .then((response) => {
        toast.success(
          isUpdate
            ? "Invoice updated successfully!"
            : "Invoice created successfully!"
        );
        navigate("/invoice");
        setShowInvoice(false);
      })
      .catch((err) => {
        console.log("Error processing invoice", err);
        toast.error("Failed to process the invoice. Complete all the fields and try again.");  // Mostrar mensaje de error
      })
      .finally(() => {
        SetisLoading(false);  // Desactivar el indicador de carga en cualquier caso
      });
};

  const [openSection, setOpenSection] = useState(""); // Estado para mantener el id de la sección abierta

  // Función para abrir y cerrar una sección
  const toggleSection = (sectionId) => {
    setOpenSection((prevSection) =>
      prevSection === sectionId ? "" : sectionId
    );
  };

  

  const sigPadCustomer = useRef({});
  const sigPadOwner = useRef({});

  const clearSignature = (sigPad) => {
    sigPad.current.clear();
  };

  const saveSignature = (sigPad, signatureField) => {
    const signatureImage = sigPad.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setFormData((prevState) => ({
      ...prevState,
      [signatureField]: signatureImage,
    }));
  };

  

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center z-50">
        <div className="text-center flex justify-center flex-col items-center">
          <IoIosSync className="animate-spin h-10 w-10 text-blue-500" />
          <p className="text-lg font-semibold text-blue-700">
            {isUpdate ? "Updating Info" : "Saving Info"}
          </p>
        </div>
      </div>
    );
  }



  return (
    <div className="flex w-[100%] relative md:w-[90%] mx-auto flex-wrap">
      <div className="fllex flex-col md:w-[50%] w-full sticky-column md:h-[100vh] overflow-y-scroll md:px-8 px-2 py-6">
        <div className="flex items-center gap-4 justify-between self-center">
          <h2 className="text-2xl font-bold">Invoice Form</h2>
        </div>
        <div>
          <h3 className="text-lg font-semibold mt-4">
            Invoice Name (Optional)
          </h3>
          <input
            type="text"
            name="invoiceName"
            value={formData.invoiceName}
            onChange={handleChange}
            placeholder="Invoice Name"
            className={inputStyle}
          />
        </div>
        <div>
          <h3 className="text-lg font-semibold mt-4">
            Invoice Number (Auto-generated)
          </h3>
          <input
            type="text"
            disabled
            name="invoiceNumber"
            value={formData.invoiceNumber}
            onChange={handleChange}
            placeholder="Invoice Number"
            className={inputStyle}
          />
        </div>

        <ToggleSection
          title="Company Data"
          isOpen={openSection === "company"}
          toggleSection={() => toggleSection("company")}
        >
          <div className="flex flex-col gap-6">
            <label>
              License
              <input
                type="text"
                name="companyLicense"
                value={formData.companyLicense}
                onChange={handleChange}
                placeholder="License (Optional)"
                className={inputStyle}
              />
            </label>
            <label>
              Address
              <input
                type="text"
                name="companyAddress"
                value={formData.companyAddress}
                onChange={handleChange}
                placeholder="Address"
                className={inputStyle}
              />
            </label>

            <label>
              Phone
              <input
                type="text"
                name="companyPhone"
                value={formData.companyPhone}
                onChange={handleChange}
                placeholder="Phone"
                className={inputStyle}
              />
            </label>

            <label>
              Email
              <input
                type="email"
                name="companyEmail"
                value={formData.companyEmail}
                onChange={handleChange}
                placeholder="Email"
                className={inputStyle}
              />
            </label>
          </div>
        </ToggleSection>
        <ToggleSection
          title="Client Data"
          isOpen={openSection === "client"}
          toggleSection={() => toggleSection("client")}
        >
          <label>
            Client Name
            <input
              type="text"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
              placeholder="Name"
              className={inputStyle}
            />
          </label>

          <label>
            Address
            <input
              type="text"
              name="clientAddress"
              value={formData.clientAddress}
              onChange={handleChange}
              placeholder="Address"
              className={inputStyle}
            />
          </label>
          <label>
            Phone
            <input
              type="text"
              name="clientPhone"
              value={formData.clientPhone}
              onChange={handleChange}
              placeholder="Phone"
              className={inputStyle}
            />
          </label>
        </ToggleSection>
        <ToggleSection
          title="Services"
          isOpen={openSection === "services"}
          toggleSection={() => toggleSection("services")}
        >
          {formData.items.map((item, index) => (
            <div key={index} className="">
              <div className="flex justify-end">
                <span className="mr-2">Completed</span>
                <input
                  type="checkbox"
                  name="completed"
                  checked={item.completed}
                  onChange={() => handleToggleCompleted(index)}
                />
              </div>
              <label>
                Quantity
                <input
                  type="number"
                  min={0}
                  name="quantity"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, e)}
                  placeholder="Quantity"
                  className={inputStyle}
                />
              </label>
              <label>
                Description
                <textarea
                  type="text"
                  name="description"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, e)}
                  placeholder="Description"
                  className={inputStyle}
                />
              </label>
              <label>
                Unit Price
                <input
                  type="number"
                  min={0}
                  name="unitPrice"
                  value={item.unitPrice}
                  onChange={(e) => handleItemChange(index, e)}
                  placeholder="Unit Price"
                  className={inputStyle}
                />
              </label>
            </div>
          ))}

          <button
            onClick={addItem}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
          >
            Add Service
          </button>
        </ToggleSection>

        <ToggleSection
          title="Legal Info"
          isOpen={openSection === "legal"}
          toggleSection={() => toggleSection("legal")}
        >
          <label>
            Terms and Conditions
            <textarea
              type="text"
              name="terms"
              value={formData.terms}
              onChange={handleChange}
              placeholder="Terms"
              className={inputStyle}
            />
          </label>

          <div className="flex flex-col space-y-8">
            <div>
              <label className="text-lg font-semibold mb-2 block">
                Customer Signature
              </label>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas border-2 border-gray-300",
                }}
                ref={sigPadCustomer}
              />
              <div className="mt-4 flex space-x-4">
                <button
                  onClick={() =>
                    saveSignature(sigPadCustomer, "customerSignature")
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Save Signature
                </button>
                <button
                  onClick={() => clearSignature(sigPadCustomer)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Clear Signature
                </button>
              </div>
            </div>
            <div>
              <label className="text-lg font-semibold mb-2 block">
                Owner Signature
              </label>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas border-2 border-gray-300",
                }}
                ref={sigPadOwner}
              />
              <div className="mt-4 flex space-x-4">
                <button
                  onClick={() => saveSignature(sigPadOwner, "ownerSignature")}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Save Signature
                </button>
                <button
                  onClick={() => clearSignature(sigPadOwner)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Clear Signature
                </button>
              </div>
            </div>
          </div>

          <label>
            Date
            <input
              type="text"
              name="currentDate"
              value={formData.currentDate}
              onChange={handleChange}
              placeholder="Date"
              className={inputStyle}
            />
          </label>
        </ToggleSection>
        <div className="pt-2">
          <label>Descuento (Optional)</label>
          <input
            type="number"
            min={0}
            name="descuento"
            value={formData.descuento}
            onChange={handleChange}
            placeholder="Descuento"
            className={inputStyle}
          />
        </div>
        <div className="flex flex-wrap gap-4 py-4">
          <button
            onClick={handleSubmit}
            className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded w-[40%] flex items-center justify-center gap-2 shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            <MdSend className="text-lg" />
            {isUpdate ? "Update Invoice" : "Create Invoice"}
          </button>
          
        </div>
      </div>
      <div className=" md:w-[50%] w-full md:overflow-x-none overflow-x-scroll md:m-0 my-16">
        <PrintData rpdata={rpdata} formData={formData} />
      </div>
    </div>
  );
};

export default FormInvoice;