import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function MetalRoofing() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Metal Roofing">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Metal Roofing"
          Subheader="Metal Roofing"
          bgimg={`${rpdata?.gallery?.[8]}`}
        />

<div className="mx-10 my-16 text-center justify-center flex flex-wrap gap-7">
          <div className="w-[35%] border-4 p-4 rounded " >
              <div className="z-10">
              <h3>
                    {rpdata?.works?.[3].name}
                </h3>
                <p>
                    {rpdata?.works?.[3].description}
                </p>
              </div>
            </div>
            <div className="w-[35%] border-4 p-4 rounded ">
                <h3>
                    {rpdata?.works?.[4].name}
                </h3>
                <p>
                    {rpdata?.works?.[4].description}
                </p>
            </div>
            <div className="w-[35%] border-4 p-4 rounded ">

                <h3>
                    {rpdata?.works?.[5].name}
                </h3>
                <p>
                    {rpdata?.works?.[5].description}
                </p>
            </div>
            <div className="w-[35%] border-4 p-4 rounded ">

                <h3>
                    {rpdata?.works?.[6].name}
                </h3>
                <p>
                    {rpdata?.works?.[6].description}
                </p>
            </div>
        </div>
       
      </div>
    </BaseLayout>
  );
}

export default MetalRoofing;
