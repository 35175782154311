import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function ShinglesRoofing() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Shingles Roofing">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Shingles Roofing"
          Subheader="Shingles Roofing"
          bgimg={`${rpdata?.gallery?.[1]}`}
        />

<div className="justify-center text-center items-center gap-5 flex flex-wrap-reverse my-20 mx-10">
            <div className="md:w-[40%] bg-white rounded">
                <h3>
                    {rpdata?.works?.[0].name}
                </h3>
                <p>
                    {rpdata?.works?.[0].description}
                </p>
            </div>
            <div className="w-[40%]">
                <img src={rpdata?.works?.[0].gallery} className="rounded" alt=""/>
            </div>
        </div>


        <div className="justify-center text-center items-center gap-5 flex flex-wrap-reverse my-20 mx-10">
        <div className="w-[40%]">
                <img src={rpdata?.works?.[1].gallery} className="rounded" alt=""/>
            </div>
            <div className="md:w-[40%] bg-white rounded">
                <h3>
                    {rpdata?.works?.[1].name}
                </h3>
                <p>
                    {rpdata?.works?.[1].description}
                </p>
            </div>
        </div>


        <div className="justify-center text-center items-center gap-5 flex flex-wrap my-20 mx-10">
            <div className="md:w-[40%] bg-white rounded">
                <h3>
                    {rpdata?.works?.[2].name}
                </h3>
                <p>
                    {rpdata?.works?.[2].description}
                </p>
            </div>
            <div className="w-[40%]">
                <img src={rpdata?.works?.[2].gallery} className="rounded" alt=""/>
            </div>
        </div>

        
       
      </div>
    </BaseLayout>
  );
}

export default ShinglesRoofing;
