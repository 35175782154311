import React from 'react';
import { useNavigate } from 'react-router-dom';
import authServices from './authServices';

export const  LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    authServices.logout();
    navigate('/autenticate'); // Redirige al usuario a la página de inicio de sesión tras cerrar sesión
  };

  return (
    <button onClick={handleLogout} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
      Logout
    </button>
  );
}

