import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { HiDocumentDownload } from "react-icons/hi";
import { IoIosSync } from "react-icons/io";

export const PrintData = ({ formData, rpdata }) => {
  const itemsPerPage = 10;
  const [pages, setPages] = useState([]);
  const printAreaRef = useRef(null);
  const [generatePdf, setGeneratePdf] = useState(false);

  useEffect(() => {
    let newPages = [];
    for (let i = 0; i < formData.items.length; i += itemsPerPage) {
      newPages.push(formData.items.slice(i, i + itemsPerPage));
    }
    setPages(newPages);
  }, [formData.items]);

  const generatePDF = async () => {
    setGeneratePdf(true);

    const printableArea = document.getElementById("printableArea");
    if (!printableArea) {
      setGeneratePdf(false);
      alert("No printable area found");
      return;
    }

    const htmlContent = printableArea.outerHTML;

    try {
      const response = await axios.post(
        "http://api-tools-clients-f0f98390c3dd.herokuapp.com/generate-pdf", // Asegúrate de que la URL de tu servidor sea correcta
        {
          contentHTML: htmlContent,
        },
        {
          responseType: "blob",
        }
      );

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", formData.invoiceName + ".pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setGeneratePdf(false);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
      setGeneratePdf(false);
    }
  };

  if (generatePdf) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="text-center flex justify-center flex-col items-center">
          <IoIosSync className="animate-spin h-10 w-10 text-white" />
          <p className="text-lg font-semibold text-white">
            Generating PDF, Waiting... 📄
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <button
        className="bg-[#01ACE4] text-white p-2 rounded-md mb-4  w-auto flex items-center justify-center gap-2 shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
        onClick={generatePDF}
      >
        <HiDocumentDownload className="text-lg" />
        Save as PDF
      </button>
      <div
        ref={printAreaRef}
        id="printableArea"
        className="print:py-2 print:px-8 bg-white print:mb-4 overflow-auto w-[8.3in] h-[16.7in] p-6 mb-4 relative scroll-hidden"
      >
        {pages.map((pageItems, pageIndex) => (
          <div key={pageIndex} className="printable page-break ">
            <div className="flex flex-col print-header gap-4">
              <div className="flex items-center justify-between">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F657dc3c21178470e3fe69127%2FiconAndLogo%2Flogo-wh.png?alt=media&token=c1267f74-4fb1-4c36-b8cd-bbcff97c8fd7"
                  alt="Logo"
                  className="w-[250px] object-contain"
                  width={250}
                />
                <div className="flex flex-col">
                  {formData.companyLicense && (
                    <p className="flex gap-2 items-center text-black justify-end">
                      {formData.companyLicense || "N/A"}
                      <i className="fas fa-id-card text-[#01ACE4] p-2 text-[22px] rounded-full"></i>
                    </p>
                  )}
                  <p className="flex gap-2 items-center text-black justify-end">
                    {formData.companyAddress}
                    <i className="fas fa-map text-[#01ACE4] p-2 text-[22px] rounded-full"></i>
                  </p>
                  <p className="flex gap-2 items-center text-black justify-end">
                    {formData.companyPhone}
                    <i className="fas fa-phone text-[#01ACE4] p-2 text-[22px] rounded-full"></i>
                  </p>
                  <p className="flex gap-2 items-center text-black justify-end">
                    {formData.companyEmail}
                    <i className="fas fa-envelope text-[#01ACE4] p-2 text-[22px] rounded-full"></i>
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center text-black">
                    <span className="text-lg font-semibold">INVOICE:</span>
                    <span className="text-lg font-semibold border-b-2 border-[#00AAE5] px-4 pb-2">
                      {formData.invoiceNumber}
                    </span>
                  </div>
                  <ul className="flex gap-1">
                    {rpdata?.dbSocialMedia?.redes.map((item, index) => (
                      <li key={index} className="">
                        <a
                          href={item.url}
                          alt={item.name}
                          aria-label={item.name}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className={`fab fa-${item.icon} text-[#01ACE4] p-2 text-[22px] rounded-full mb-3`}
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="z-10  p-4 text-black page-content">
              {/* Client Information solo en la primera página */}
              {pageIndex === 0 && (
                <div className="mb-6 pt-5">
                  <h3 className="text-lg font-semibold pb-2">
                    Client Information:
                  </h3>
                  <div className="flex flex-col gap-2 pl-5 border-l-2 border-[#00AAE5]">
                    <div className="flex gap-4">
                      <p className="font-medium">Name:</p>
                      <div className="flex w-full border-b-2 pb-1 border-black">
                        <p>{formData.clientName}</p>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <p className="font-medium">Address:</p>
                      <div className="flex w-full border-b-2 pb-1 border-black">
                        <p>{formData.clientAddress}</p>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <p className="font-medium">Phone:</p>
                      <div className="flex w-full border-b-2 pb-1 border-black">
                        <p>{formData.clientPhone}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-6">
                <h3 className="text-lg font-semibold">Items:</h3>
                <table className="min-w-full">
                  <thead>
                    <tr className="border-b">
                      <th className="px-4 py-2 text-left">Quantity</th>
                      <th className="px-4 py-2 text-left">Description</th>
                      <th className="px-4 py-2 text-left">Unit Price</th>
                      <th className="px-4 py-2 text-left">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageItems.map((item, index) => (
                      <tr key={index} className="border-b">
                        <td className="px-4 py-2">{item.quantity}</td>
                        <td className="px-4 py-2 description-cell">
                          {item.description}
                        </td>
                        <td className="px-4 py-2">{item.unitPrice}</td>
                        <td className="px-4 py-2">
                          {item.quantity * item.unitPrice}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Firmas solo en la última página */}
              {pageIndex === pages.length - 1 && (
                <>
                  <div className="mb-6">
                    <p>Subtotal: {formData.subtotal}</p>
                    <p>Descuento: {formData.descuento}</p>
                    <p>Total: {formData.total}</p>
                  </div>
                  <div className="text-center py-4">
                    <h3 className="text-lg font-semibold">Terms:</h3>
                    <p>{formData.terms}</p>
                  </div>
                  <div className="signatures">
                    <div className="mb-6">
                      <div className="flex gap-4 justify-between">
                        <div className="w-[250px] border-b-2 border-black pb-2 flex justify-center flex-col text-center">
                          <p>Customer Signature</p>
                          {/* Previsualización de la firma del cliente */}
                          {formData.customerSignature ? (
                            <img
                              src={formData.customerSignature}
                              alt="Customer Signature"
                              style={{ height: "100px" }}
                            />
                          ) : (
                            <p className="py-1">No signature available</p>
                          )}
                        </div>
                        <div className="w-[250px] border-b-2 border-black pb-2 flex justify-center flex-col text-center">
                          <p>Owner</p>
                          {/* Previsualización de la firma del propietario */}
                          {formData.ownerSignature ? (
                            <img
                              src={formData.ownerSignature}
                              alt="Owner Signature"
                              style={{ height: "100px" }}
                            />
                          ) : (
                            <p className="py-1">No signature available</p>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <div className="w-[250px] border-b-2 border-black flex justify-center flex-col text-center">
                          <p>Date</p>
                          <p>{formData.currentDate}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <img src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F657dc3c21178470e3fe69127%2FiconAndLogo%2Flogo-wh.png?alt=media&token=c1267f74-4fb1-4c36-b8cd-bbcff97c8fd7" className="absolute print:fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-10 w-[500px] " alt="Logo watermark" />
          </div>
        ))}
      </div>
    </div>
  );
};
