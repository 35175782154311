// src/ProtectedRoute.js
import React from 'react';
import authServices from './authServices';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const currentUser = authServices.getCurrentUser();

    if (!currentUser) {
        // Redireccionar a la página de login si no hay un usuario autenticado
        return <Navigate to="/autenticate" replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
