import axios from "axios";
import authServices from "./authServices";

const API_URL = 'https://api-tools-clients-f0f98390c3dd.herokuapp.com/';
// const API_URL = 'http://localhost:3001/';

class InvoiceController {
  // Método para obtener una factura por ID
  getInvoiceById(id) {
    const user = authServices.getCurrentUser();
    if (user && user.token) {
      return axios
        .get(API_URL + "invoices/" + id, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      throw new Error("No user logged in or token expired");
    }
  }

  // Método para obtener todas las facturas de un cliente específico por clienteId
  getInvoicesByClientId(clienteId) {
    const user = authServices.getCurrentUser();
    if (user && user.token) {
      return axios
        .get(API_URL + "invoices/client/" + clienteId, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      throw new Error("No user logged in or token expired");
    }
  }

  // Método para crear una nueva factura
  createInvoice(invoiceData) {
    const user = authServices.getCurrentUser();
    if (user && user.token) {
      return axios
        .post(API_URL + "invoices", invoiceData, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      throw new Error("No user logged in or token expired");
    }
  }

  // Método para actualizar una factura
  updateInvoice(id, invoiceData) {
    const user = authServices.getCurrentUser();
    if (user && user.token) {
      return axios
        .put(API_URL + "invoices/" + id, invoiceData, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      throw new Error("No user logged in or token expired");
    }
  }
}

export default new InvoiceController();
